import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider"] */ "/app/apps/ahafood/src/lib/react-query/query-client-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/apps/ahafood/src/providers/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/ahafood/src/providers/is-ssr-mobile-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/apps/ahafood/src/providers/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/ahafood/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/lib/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./svn-gilroy-regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./svn-gilroy-medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./svn-gilroy-semibold.ttf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./svn-gilroy-bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-gilroy\",\"fallback\":[\"sans-serif\",\"system-ui\",\"arial\"]}],\"variableName\":\"gilroy\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/packages/ui/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/styles.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts/index.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
