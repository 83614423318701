'use client';

import { useContext, createContext, useState, useEffect } from 'react';

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Don't forget to remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

export const IsSsrMobileContext = createContext(false);

export function useIsMobile() {
  const isSsrMobile = useContext(IsSsrMobileContext);
  const { width: windowWidth } = useWindowSize();
  const isBrowserMobile = Boolean(windowWidth) && windowWidth < 768;

  return isSsrMobile || isBrowserMobile;
}

interface IsSsrMobileProviderProps {
  children: React.ReactNode;
  isSsrMobile: boolean;
}

export default function IsSsrMobileProvider({
  children,
  isSsrMobile,
}: IsSsrMobileProviderProps) {
  return (
    <IsSsrMobileContext.Provider value={isSsrMobile}>
      <>{children}</>
    </IsSsrMobileContext.Provider>
  );
}
